<template>
  <div class="about-container">
    <div class="top-img"></div>
    <div class="title-name">
      <Title :title="'公司介绍'" :enTitle="'SELF INTRODUCTION'"></Title>
    </div>
    <div class="introductory">
      <div class="left-img"></div>
      <div class="right">
        <div class="content">{{ contentNew }}</div>
        <!-- <div>{{ content }}</div>
        <div>{{ contentTwo }}</div>
        <div>{{ contentThree }}</div> -->
      </div>
    </div>
    <div class="title-name">
      <Title :title="'联系我们'" :enTitle="'CONTACT  US'"></Title>
    </div>
    <div class="map-box">
      <div id="container"></div>
      <div class="map-message">
        <div class="company-name">安徽数智建造研究院有限公司</div>
        <div class="msg-item">
          <div class="icon"></div>
          <span>{{servicePhone}}</span>
        </div>
        <!-- <div class="msg-item">
          <div class="icon mail"></div>
          <span>ctce_hjc@163.com</span>
        </div> -->
        <div class="msg-item">
          <div class="icon address"></div>
          <span>安徽省合肥市包河区中铁科技大楼</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Title from "@/components/Title";
import AMap from "AMap";
import { mapGetters } from "vuex";
export default {
  components: {
    Title,
  },
  data() {
    return {
      contentNew:"安徽数智建造研究院有限公司成立于2015年，公司聚焦建筑行业数字化、工业化、智能化、绿色化发展及前沿新兴业态产品的研发推广，致力于打造“三商一库”（数字化转型集成商、智慧建造服务商、建筑智能产品综合提供商、行业高质量发展战略智库）。公司现有员工300余名，高级职称、研究生及以上学历人员占50%，设博士后科研工作站，具有国务院国资委“科改企业”、国家高新技术企业、国家科技型中小企业、安徽省大数据企业等多个科研平台。累计承担各级科研课题80余项，申请各类知识产权200余项，获省部级及以上奖项300余项。",
      content:
        "安徽数智建造研究院有限公司成立于2016年5月，是世界500强中国中铁旗下的标杆企业中铁四局集团有限公司全资子公司，与中铁四局集团管理与技术研究院实行“一个机构，两块牌子”管理模式，是建筑企业的技术与管理智库，集“科技研发+管理创新+科研成果产业化”为一体的新型科创公司，也是企业集团思想、战略、管理、技术、产业创新的集成化平台。",
      contentTwo:
        "公司自成立以来，先后与北京大学、清华大学、中国科学技术大学、中国铁道科学研究院、中科院心理研究所等科研院所，以及广联达、达索、鲁班、华夏基石、攀成德等知名企业建立了紧密的合作关系，围绕BIM信息化系统、智能前端感知系统、绿色装备研发、管理软科学等领域开展研究工作，取得了一系列研究成果。",
      contentThree:
        "公司坚持以服务企业为根本，以市场为导向，以价值创造为目标，聚焦建筑业工业化、数字化、智能化和前沿新兴业态产品的研发推广，强化管理咨询服务能力，打造“研产销+咨询服务”一体化平台，致力成为具有行业领导力的数字化转型集成商、智慧建造服务商、企业高质量发展战略智库。",
      aMap: null,
    };
  },
  computed:{
    ...mapGetters(['servicePhone'])
  },
  mounted() {
    this.initMap();
  },
  methods: {
    // 加载地图
    initMap() {
      let map = new AMap.Map("container", {
        zoom: 17, //级别
        zooms: [3, 18],
        center: [117.267895, 31.818303], //中心点坐标
        size: new AMap.Size(24, 24),
        scrollWheel: false,
        dragEnable: false,
        doubleClickZoom: false,
      });
      let marker = new AMap.Marker({
        position: [117.267895, 31.818303], //位置
        icon: new AMap.Icon({
          image: require("../../assets/img/about/map-icon.png"), //点标记图片路径
          imageSize: new AMap.Size(24, 28),
        }),
      });
      map.add(marker); //添加到地图
      this.aMap = map;
    },
  },
};
</script>

<style lang="less" scope>
@import "./index.less";
</style>
